<template>
  <div class="d-flex flex-column py-4">
    <v-row>
      <v-col class="col-12" v-if="loading">
        <v-card>
          <v-card-text class="wait">
            <h2>Loading...</h2>
            <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12" v-else-if="found === false">
        <v-card>
          <v-card-text class="wait">
            <h2>No record was found.</h2>
            <v-icon size="80" color="primary"> mdi-close-octagon-outline </v-icon>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12" v-else>
        <div class="headet-top">
          <h2>Request Client Account</h2>
          <div class="header-actions">
            <v-btn color="primary" class="sm-12 btn-submit" @click="showDialog(true)" :disabled="sending_approve">
              <span v-if="loading === false">
                <v-icon style="margin-right: 5px"> mdi-check </v-icon>
                <span>Approve</span>
              </span>
              <span v-else>
                <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
                <span>Processing...</span>
              </span>
            </v-btn>
            <v-btn color="error" class="sm-12 btn-submit" @click="showDialog(false)" :disabled="sending_reject">
              <span v-if="loading === false">
                <v-icon style="margin-right: 5px"> mdi-close </v-icon>
                <span>Reject</span>
              </span>
              <span v-else>
                <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
                <span>Processing...</span>
              </span>
            </v-btn>
          </div>
        </div>

        <v-card style="margin-top: 20px">
          <v-card-text>
            <v-form ref="form" class="multi-col-validation mt-6">
              <v-row>
                <v-col md="6" cols="12">
                  <span class="text"><b>Name:</b> {{ data.name }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>Email:</b> {{ data.email }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>Website:</b> {{ data.website }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>Phone:</b> {{ data.phone }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>Service:</b> {{ data.service }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>Address:</b> {{ data.address }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>Address2:</b> {{ data.address2 }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>City:</b> {{ data.city }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>State:</b> {{ data.state }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>Zip:</b> {{ data.zip }}</span>
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text"><b>Country:</b> {{ getCountryName(data.country) }}</span>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <dialog-review
          :show.sync="dialog.show"
          :approve="dialog.approve"
          :title="dialog.title"
          :description="dialog.description"
          :action="submit"
        ></dialog-review>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'
import countries from '@/const/countries'
//components
import DialogReview from './components/DialogReview.vue'

export default {
  components: {
    DialogReview,
  },
  data() {
    return {
      loading: false,
      sending_approve: false,
      sending_reject: false,
      found: null,
      errors: null,
      data: {},
      countries,
      validators: {
        required,
        emailValidator,
        urlValidator,
        integerValidator,
      },
      dialog: {
        title: '',
        description: '',
        approve: null,
        show: false,
      },
    }
  },
  mounted() {
    this.getRequestData()
  },
  methods: {
    ...mapActions({
      getRequest: 'requests/getRequest',
      resolve: 'requests/resolve',
      setSnackbar: 'snackbar/set',
    }),
    async getRequestData() {
      this.loading = true
      await this.getRequest(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
          }
        })
        .catch(error => {
          this.found = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const formData = {
          request_id: this.data.id,
          approved: this.dialog.approve,
        }

        if (formData.approved) {
          this.sending_approve = true
        } else {
          this.sending_reject = true
        }

        this.resolve(formData)
          .then(resp => {
            if (resp && resp.data) {
              if (resp.data.status == 'ok') {
                this.setSnackbar({
                  status: true,
                  text: 'Request successfully processed.',
                  color: 'primary',
                })

                this.errors = null
                this.goToPath('/support/requests/list')
              }
            }
          })
          .catch(error => {
            console.log(error)
            if (error.response.data && error.response.data.status === 'error') {
              this.errors = error.response.data.errors
            }

            this.setSnackbar({
              status: true,
              text: 'Failure to process request.',
              color: 'error',
            })
          })
          .finally(() => {
            this.sending_approve = false
            this.sending_reject = false
          })
      }
    },
    showDialog(approve) {
      if (approve) {
        this.dialog.title = 'Are you sure you approve this request?'
        this.dialog.description =
          'Once the application is approved, your customer profile will be created and you will be able to use the reporting tools. Would you like to continue?'
        this.dialog.show = true
        this.dialog.approve = true
      } else {
        this.dialog.title = 'Are you sure you reject this request?'
        this.dialog.description =
          'If the request is rejected, the user will be denied the creation of a client account and will not be able to use the reporting tools. Would you like to continue?'
        this.dialog.show = true
        this.dialog.approve = false
      }
    },
    getCountryName(name) {
      const country = countries.find(c => c.code == name)
      if (country) {
        return country.name
      }
      return name
    },
    goToPath(path) {
      this.$router.push({ path })
    },
  },
}
</script>

<style>
.headet-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.headet-top .header-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
}
.wait {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.text {
  font-size: 16px;
}

@media (max-width: 767px) {
  .headet-top {
    flex-direction: column;
  }
  .headet-top .header-actions {
    flex-direction: column;
  }
}
</style>
