<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>{{ description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            outlined
            @click="closeDialog()"
          >
            Close
          </v-btn>
          <v-btn
            :color="approve ? 'primary' : 'error'"
            outlined
            @click="actionDialog()"
          >
            {{ approve ? 'Approve' : 'Reject' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: {
      show: Boolean,
      title: String,
      description: String,
      action: { type: Function },
      item: Object,
      approve: Boolean
    },
    data () {
      return {}
    },
    methods: {
      closeDialog(){
        this.$emit('update:show', false);
      },
      actionDialog(){
        this.$emit('update:show', false);
        this.action();
      },
    },
  }
</script>
